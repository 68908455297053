import React from 'react';
import DriverChecksimgG from "assets/img/Group-37142-1.png";
import useMediaQuery from 'utils/CustonHooks/UseDeviceResponsiveHook';

const Test = () => {
  const matches = useMediaQuery("(min-width: 768px)");

  return (
    <div>
      {/* {matches ? <h1>Big Screen</h1> : <h3>Small Screen</h3>} */}

      {/* <section className="OneApp-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-6 text-lg-start">
                  <h2 className="fade-right">
                    One App, Infinite <br />
                    <span style={{ color: "#7ebcb1" }}>Possibilities</span>
                  </h2>
                  {!matches && <div className='text-center'><img className="phone-2" src={DriverChecksimgG} style={{ textAlign:"center" }}/></div>}
                  <p>
                    TecHGV offers a world of infinite possibilities when it
                    comes to fleet management and vehicle tracking. With our
                    innovative solutions and advanced technology, we empower
                    businesses to unlock new levels of efficiency, productivity,
                    and legal compliance.
                  </p>
                  <br />
                  <p>
                    One of the key strengths of TecHGV lies in its ability to
                    provide comprehensive fleet management software that caters
                    to the unique needs of each client. Whether it’s a small
                    business with a handful of vehicles or a large enterprise
                    with an extensive fleet, TecHGV has the tools and expertise
                    to optimise fleet operations.
                  </p>
                </div>
                <div className="col-lg-6 iphone-wrap text-center">
                  {matches && <img className="phone-2" src={DriverChecksimgG} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div className="menu-dropdown-section">
       <div class="container">
        <div class="industry-section row">
            <div class="industries col-md-4">
                <h3>By Industry</h3>
                <ul class="list-unstyled">
                    <li>
                        <a href="#">
                            <div class="icon">🏗️</div>
                            <span>Construction</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <div class="icon">🔧</div>
                            <span>Field Service</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <div class="icon">⛽</div>
                            <span>Oil & Gas</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <div class="icon">🚚</div>
                            <span>Delivery</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <div class="icon">🚛</div>
                            <span>Trucking & Logistics</span>
                        </a>
                    </li>
                    
                </ul>
            </div>
            <div class="industries col-md-4" style={{ marginTop:"40px" }}>
                
                <ul class="list-unstyled">
                    <li>
                        <a href="#">
                            <div class="icon">🏢</div>
                            <span>Public Sector</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <div class="icon">🍔</div>
                            <span>Food & Beverage</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <div class="icon">🚌</div>
                            <span>Passenger Transit</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <div class="icon">🚜</div>
                            <span>Agriculture</span>
                        </a>
                    </li>
                    
                </ul>
            </div>
            {/* <!-- Featured Column --> */}
            <div class="featured col-md-4">
                {/* <img src="https://via.placeholder.com/400x200" alt="Featured Image" class="img-fluid"> */}
                <h4>Cascade improves safety by 50%, reclaims ~10K hours per year.</h4>
                <p>With the Motive platform, Cascade has a consolidated view of fleet and spend management across its fleet of more than 3,000 vehicles.</p>
            </div>
        </div>
    </div> 
    </div>
    
    </div>
  );
}

export default Test;
