export const Header_Data = [
  {
    link_title: "Products",
    route: "/products",
    heading: "Featured",
    feauters: ["AI Dashcam", "AI Dashcam", "AI Dashcam", "AI Dashcam"],
    multiRoutes: [
      {
        heading: "Tracking Solution",
        icon: "",
        routes: [
          "Driving History Section",
          "Tracking Section",
          "Tracking Section",
        ],
      },
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: ["Driving History Section", "Tracking Section"],
      },
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: ["Driving History Section", "Tracking Section"],
      },
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: ["Driving History Section", "Tracking Section"],
      },
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: ["Driving History Section", "Tracking Section"],
      },
    ],
  },
  {
    link_title: "Pricing",
    route: "/pricing",
    heading: "By industry",
    multiRoutes: [
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: [
          "Driving History Section",
          "Tracking Section",
          "Tracking Section",
        ],
      },
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: ["Driving History Section", "Tracking Section"],
      },
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: ["Driving History Section", "Tracking Section"],
      },
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: ["Driving History Section", "Tracking Section"],
      },
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: ["Driving History Section", "Tracking Section"],
      },
    ],
  },
  {
    link_title: "Order a Device",
    route: "order-a-device",
    heading1: "Featured1",
    multiRoutes: [
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: [
          "Driving History Section",
          "Tracking Section",
          "Tracking Section",
        ],
      },
      {
        heading: "Tracking Solutions",
        icon: "",
        routes: ["Driving History Section", "Tracking Section"],
      },
    ],
  },
  { link_title: "Book a Demo", route: "book-a-demo",heading1:"Featured1" },
  { link_title: "Get a Free Quote", heading1:"Featured1", route: "Get-a-Free-Quote" },
  { link_title: "Blog", heading1:"Featured1", route: "blog" },
];
