import React from "react";
import weblogo from "assets/img/logo.png";
import "assets/js/main";
import MegaDropdown from "components/Dropdowns";
import "components/header/header.css";
import { Header_Data } from "data/Header";
import { useRef, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink } from "react-router-dom";
export default function Header() {
  const [expanded, setExpanded] = useState(false);
  const closeNavbar = () => setExpanded(false);
  const Mega_Hover_Ref = useRef([]);
  const OnMouseEnter = (index) => {
    Mega_Hover_Ref?.current[index].classList.add("display-block");
  };

  ///////////////////////////////////////////////////////////////////////////////////
  const OnMouseLeave = (index) => {
    Mega_Hover_Ref?.current[index].classList.remove("display-block");
  };
  ////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="container d-flex justify-content-between align-items-center">
      <Navbar
        expand="lg"
        expanded={expanded}
        className="w-100 d-flex justify-content-between"
      >
        <Navbar.Brand>
          <div className="logo">
            <Link to="/">
              <img className="img-fluid" src={weblogo} />
            </Link>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav onClick={closeNavbar}>
            {Header_Data?.map((item, index) => (
              <NavLink
                onMouseEnter={() => OnMouseEnter(index)}
                onMouseLeave={() => OnMouseLeave(index)}
                className="nav-link position-relative"
                to={item?.route}
              >
                {item?.link_title}
                <MegaDropdown
                  html={item?.innerHtml}
                  className={"position-absolute"}
                  ref={(ref) => (Mega_Hover_Ref.current[index] = ref)}
                  data={item?.multiRoutes}
                  features={item?.feauters} 
                  heading={item.heading}
                  
                />
              </NavLink>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
         
    </div>
  );
}
