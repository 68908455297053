import React from "react";
import "components/ServicesPages/style.css";
import FuelAdblueUsageimg from "assets/img/Fuel-Adblu-Usage-mobile-1.png";
import TorqueRegisterimg from "assets/img/Torque-Register-mobile-1.png";
import DriverChecksimg from "assets/img/Driver-Checks-mobile-1.png";
import RequestADemo from "assets/img/Vehicle-Alignment.jpeg";
import PhoneImg from "assets/img/main-img-1.png";
import { Link } from "react-router-dom";
import { ServicesPage_Data } from "data/ServicesPage";

const ServicesPage = ({  }) => {
  return (
    <div>
       {ServicesPage_Data?.map((item, index) => (
      <>
      <div className="services-page-section">
        <div className="container">
         
            <div className="row" key={index}>
              <div className="main-heading col-lg-6">
                <h1 style={{ padding: "0px" }}  dangerouslySetInnerHTML={{ __html: item?.Main_title }}/>
                 
                <p style={{ textAlign: "start" }} dangerouslySetInnerHTML={{ __html: item?.Main_Description }} />
              </div>
              <div className="col-lg-6" style={{ textAlign: "end" }}>

                <img className="phone-2" src={item?.Main_img} alt={item?.Main_title} />
              </div>
            </div>
         
        </div>
      </div>
      <div className="ServicesPage-Driver-Checks-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 style={{ padding: "0px 0px 20px 0px" }}>Key Features</h2>
            </div>
          </div>
          <div className="row align-center">
            <div className="col-md-4 text-center">
              <div className="feature-1">
                <img className="DriverChecksimg" src={item?.KeyFeatures_1stImg} alt={item?.KeyFeatures_1stImg} />
                <h3 className="mb-3">Real-Time Location Tracking</h3>
                <p>
                  Access live GPS data to monitor vehicle locations
                  continuously.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="feature-1">
                <img className="DriverChecksimg" src={item?.KeyFeatures_2ndImg} alt={item?.KeyFeatures_2ndImg} />
                <h3 className="mb-3">Historical Data Analysis</h3>
                <p>
                  Review past trips, speeds, and routes to identify patterns and
                  improve safety measures.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="feature-1 ">
                <img className="DriverChecksimg" src={item?.KeyFeatures_3rdImg} alt={item?.KeyFeatures_3rdImg} />
                <h3 className="mb-3">Driver Behavior Assessment</h3>
                <p>
                  Analyze driving behaviors, such as acceleration, braking, and
                  cornering, to promote safe driving habits.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Benefits-Driver-Checks-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 style={{ padding: "0px 0px 20px 0px" }}>Benefits</h2>
            </div>
          </div>
          <div className="row align-center">
            <div className="col-md-3 text-center">
              <div className="feature-1">
                <i class="fa fa-shield" aria-hidden="true"></i>
                <h3 className="mb-3">
                Enhanced  <br />
                Safety
                </h3>
                <p>
                Track driving habits to minimize risky behaviors and ensure safer driving practices.
                </p>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="feature-1">
                <i class="fa fa-shield" aria-hidden="true"></i>
                <h3 className="mb-3">
                Increased  <br />
                Accountability
                </h3>
                <p>
                Provide drivers with performance feedback, fostering a culture of responsibility within your fleet.
                </p>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="feature-1">
                <i class="fa fa-shield" aria-hidden="true"></i>
                <h3 className="mb-3">
                  Insurance Cost <br />
                  Savings
                </h3>
                <p>
                  Lower accident rates can result in reduced insurance premiums
                  for your fleet.
                </p>
              </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="feature-1">
                <i class="fa fa-shield" aria-hidden="true"></i>
                <h3 className="mb-3">
                  Regulatory <br />
                  Compliance
                </h3>
                <p>
                  Maintain accurate driving records to comply with
                  transportation regulations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Use-Cases-Driver-Checks-sec">
        <div className="container">
          <div className="row" style={{ paddingTop: "0px" }}>
            <div className="col-md-12">
              <h2 style={{ padding: "0px 0px 0px 0px" }}>Use Cases</h2>
            </div>
          </div>
          <div className="row align-center">
            <div className="col-md-4 BoxShadow">
              <div className="feature-2"> 
                <img className="Use-Cases-img" src={RequestADemo} />
                <div className="feature-1">
                <h3 className="mb-3">Fleet Managers</h3>
                <p>
                Utilize driving history data for targeted driver training and risk mitigation.
                </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 BoxShadow">
              <div className="feature-2">
                <img className="Use-Cases-img" src={RequestADemo} />
                <div className="feature-1">
                <h3 className="mb-3">Insurance Providers</h3>
                <p>
                Access comprehensive driving history to assess risk more accurately.
                </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 BoxShadow">
              <div className="feature-2">
                <img className="Use-Cases-img" src={RequestADemo} />
                <div className="feature-1">
                  <h3 className="mb-3">Logistics Companies</h3>
                  <p>
                  Optimize routing based on historical performance, enhancing delivery efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Getting-Started-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 style={{ padding: "0px 0px 20px 0px" }}>
                Getting Started <br />
                <span style={{ color: "#7ebcb1" }}> with TechGVs </span>
              </h2>
              <p>Implementing driving history tracking in your fleet is simple:</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="feature-1" style={{ paddingTop: "7em" }}>
                <h3 className="mb-3">Request a Demo</h3>
                <p>Schedule a personalized demonstration to see our tracking solutions in action.
                </p>
              </div>
            </div>
            <div className="col-md-6" style={{ paddingTop: "15px" }}>
              <img className="phone-2" src={RequestADemo} />
            </div>
          </div>
          <div className="row" style={{ padding: "40px 0px 40px 0px" }}>
            <div className="col-md-6" style={{ paddingTop: "15px" }}>
              <img className="phone-2" src={RequestADemo} />
            </div>
            <div className="col-md-6">
              <div className="feature-1" style={{ paddingTop: "7em" }}>
                <h3 className="mb-3">Choose Your Plan</h3>
                <p>Select from flexible pricing options tailored to your needs.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="feature-1" style={{ paddingTop: "7em" }}>
                <h3 className="mb-3">Integrate Seamlessly</h3>
                <p>
                Our team will assist you in integrating the tracking solution into your existing fleet imanagement system.
                </p>
              </div>
            </div>
            <div className="col-md-6" style={{ paddingTop: "15px" }}>
              <img className="phone-2" src={RequestADemo} />
            </div>
          </div>
        </div>
      </div>
      <div className="Call-to-Action-sec">
        <div className="container">
          {/* <div className="row">
            <div className="col-md-12">
              <h2 style={{ padding: "0px 0px 20px 0px" }}>Use Cases</h2>
            </div>
          </div> */}
          <div className="row ase">
            <div
              className="col-md-8"
              style={{
                backgroundColor: "#7ebcb1",
                height: "200px",
                marginTop: "auto",
                width: "45%",
                padding: "50px 30px 50px 30px",
                textAlign: "start",
              }}
            >
              <div className="feature-1">
                <h3 className="mb-3">Get Started Today</h3>
                <p style={{ textAlign: "start" }}>
                  Motive can automate and scale your operations
                </p>
                <br />
                <Link
                  to="/your-path"
                  className="btn btn-light"
                  role="button"
                  style={{ padding: "5px 30px 5px 30px" }}
                >
                  Get a Free Quote
                </Link>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
      </>
       ))}
    </div>

  );
};

export default ServicesPage;
