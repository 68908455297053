import React from "react";
import "components/DropdownsPage/index.css";
import { Link } from "react-router-dom";

const DropdownsPage = ({ data, features, index, heading }) => {
  return (
    <div>
      <div className="menu-dropdown-section">
        <div className="container d-flex">
          <div className="row">
            <div className="products1 col-md-12`">
            <h4>{heading}</h4>
              <div className="row">
                {data?.map((item, index) => (
                  
                  <div className="col-md-6">
                    <ul className="list-unstyled d-flex">
                      <li>
                       
                          <div className="icon">💳</div>
                          <div>
                          <Link to="#">
                            <h5>{item?.heading}</h5>
                            </Link>
                            {item?.routes?.map((item) => (
                              <p>{item}</p>
                            ))}
                          </div>
                        
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
              {index == 0 &&
                <Link to="#" className="view-all">
                  View All Products →
                </Link>
}
            </div>
            {/* <div className="featured1 col-md-3"></div> */}
          </div>

          {features && (
            <div className="featured1 col-md-3">
              <h4>Featured</h4>
              <ul className="list-unstyled">
                {features?.map((item) => (
                  <li>
                    <Link to="#">{item}</Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="image col-md-3">
            <img
              src="https://via.placeholder.com/400x200"
              alt="ROI Savings Calculator"
            />
            <h5>How much will you save with Motive?</h5>
            <p>
              Improve your bottom line with Motive's cutting-edge technology
              that enhances safety, boosts productivity, and drives
              profitability.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownsPage;
