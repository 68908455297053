import PhoneImg from "assets/img/main-img-1.png";

import FuelAdblueUsageimg from "assets/img/Fuel-Adblu-Usage-mobile-1.png";
import TorqueRegisterimg from "assets/img/Torque-Register-mobile-1.png";
import DriverChecksimg from "assets/img/Driver-Checks-mobile-1.png";

export const ServicesPage_Data = [
  {
    route_url: "driving-history-tracking",
    Main_title: "Driving History <br /> <span style='color: #7ebcb1;'>Tracking</span>",
    heading: "heading1",
    Main_Description: "<strong> Driving history tracking</strong> is essential for effective fleet management. Our advanced tracking solutions empower businesses to monitor driving behavior, enhance safetystandards, and improve operational efficiency.",
    Main_img: PhoneImg,
    // Key Features

    KeyFeatures_1stImg: DriverChecksimg,
    KeyFeatures_1stHeading: "Real-Time Location Tracking",
    KeyFeatures_1stDescription:"Access live GPS data to monitor vehicle locations continuously.",
    KeyFeatures_2ndImg: TorqueRegisterimg,
    KeyFeatures_1stHeading: "Historical Data Analysis",
    KeyFeatures_1stDescription:"Review past trips, speeds, and routes to identify patterns and improve safety measures.",
    KeyFeatures_3rdImg: FuelAdblueUsageimg,
    KeyFeatures_1stHeading: "Driver Behavior Assessment",
    KeyFeatures_1stDescription:"Analyze driving behaviors, such as acceleration, braking, and cornering, to promote safe driving habits.",

  },
];