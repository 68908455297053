import React, { forwardRef, useEffect } from "react";
import "components/Dropdowns/index.css";
import DropdownsPage from "components/DropdownsPage/DropdownsPage";

const MegaDropdown = forwardRef(({ className, data,features,index, heading }, ref) => {

  return (
    <div ref={ref} className={className}>
      <div className="container mega-container animated-box  animate__animated  animate__bounceInUp">
        <DropdownsPage data={data} features={features} index={index} heading={heading} />
      </div>
    </div>
  );
});

export default MegaDropdown;
